import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    list(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/categories', {params: queryParams})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    show(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/categories/${id}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    delete(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/categories/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    store(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/categories`, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    update(ctx, {id, data}) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/categories/${id}`, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
  },
}
